<template>
  <div class="MolecularGeneration">
    <h1 class="title">Similarity Search</h1>
    <div class="Input">
      <section>
        <div class="classification">
          <h2 class="small-title">Select a Classification</h2>
          <el-select
            class="classfication-input"
            size="large"
            placeholder="Please Select Classification"
            v-model="selectedClassfication"
            filterable
          >
            <el-option
              v-for="item in classification"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="fingerprint">
          <h2 class="small-title">Select Fingerprint</h2>
          <el-select
            class="fingerprint-input"
            size="large"
            placeholder="Please Select Fingerprint"
            v-model="selectedFingerprint"
            filterable
          >
            <el-option
              v-for="item in fingerprint"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="range">
          <h2>Similarity Range</h2>
          <div>
            <!-- From <el-input v-model="minscore"></el-input> to
            <el-input v-model="maxscore"></el-input> -->
            <el-slider
              v-model="score"
              @change="setScore"
              range
              :max="1"
              :step="0.01"
            />
          </div>
        </div>
      </section>
      <section>
        <div>
          <h2 class="small-title">Submit with an molecule SMILES</h2>
          <el-input
            placeholder="Please enter the formula"
            class="smiles-input"
            size="large"
            v-model="molecularFormula"
          ></el-input>
          <iframe
            src="./static/jsme.html"
            frameborder="0"
            class="draw-molecular"
            height="340px"
            width="450px"
          ></iframe>
        </div>
      </section>
    </div>
    <div class="submit">
      <img class="img" src="../assets/singleprediction/photo1.png" />
      <el-button class="submit-button" @click="onSubmit">Submit</el-button>
    </div>
    <similarity-popper
      v-show="showPopper"
      :infos="popperInfo"
      @onSubmit="onSubmitPopper"
      @onCancel="onCancelPopper"
    ></similarity-popper>
  </div>
</template>

<script>
import { similarityApi } from "../api/index";
import { molecularGenerationApi } from "../api/index";
import popper from "../components/popper/predict.vue";

export default {
  name: "contact",
  data() {
    return {
      selectedClassfication: "",
      selectedFingerprint: "",
      molecularFormula: "",
      value: "",
      modelList: [],
      minscore: 0,
      score: [0, 1],
      maxscore: 1,
      showPopper: false,
      email: "",
      isShowAll: [0, 0, 0, 0, 0, 0],
      popperInfo: {},
      taskName: "",
      model: {
        "Anti-cancer Targets": [],
        Kinase: [],
        "Virus Targets": [],
      },
      classification: [
        "Cancer Cell Lines",
        "Anti-cancer Targets",
        "NCI60 Cancer Cell Lines",
      ],
      catagory: {
        "Cancer Cell Lines": "cancer_cell",
        "Anti-cancer Targets": "cancer_target",
        "NCI60 Cancer Cell Lines": "nci60_cancer_cell",
      },
      fingerprint: [
        "Morgan FingerPrint",
        "MACCS FingerPrint",
        "AtomPairs FingerPrint",
      ],
      fingerPrint: {
        "Morgan FingerPrint": "Morgan",
        "MACCS FingerPrint": "MACCS",
        "AtomPairs FingerPrint": "AtomPairs",
      },
      searchValue: null,
    };
  },
  created() {
    window.getFromIframe = this.getFromIframe;
  },
  methods: {
    onClickShowAll(idx) {
      this.isShowAll[idx] = !this.isShowAll[idx];
    },
    onSubmitPopper(taskName, email) {
      this.taskName = taskName;
      this.email = email;
      var reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (reg.test(this.email)) {
        this.SubmitGeneration();
        this.showPopper = false;
      } else {
        this.$store.commit("alertMsg", {
          msg: "The format of email is wrong!",
          type: "error",
        });
      }
    },
    onCancelPopper() {
      this.showPopper = false;
      console.log("oncancel");
    },
    setScore() {
      this.maxscore = this.score[1];
      this.minscore = this.score[0];
      console.log(this.minscore, this.maxscore);
    },
    checkForm() {
      if (this.selectedClassfication == "") {
        this.$store.commit("alertMsg", {
          msg: "No Classfication is selected!",
          type: "error",
        });
        return false;
      } else if (this.selectedFingerprint == "") {
        this.$store.commit("alertMsg", {
          msg: "No Fingerprint is selected!",
          type: "error",
        });
        return false;
      } else if (this.molecularFormula == "") {
        this.$store.commit("alertMsg", {
          msg: "Please enter smile!",
          type: "error",
        });
        return false;
      } else if (this.minscore >= this.maxscore) {
        this.$store.commit("alertMsg", {
          msg: "The range is wrong!",
          type: "error",
        });
        return false;
      }
      return true;
    },
    onSubmit() {
      if (this.checkForm()) {
        this.popperInfo = {
          Classification: this.selectedClassfication,
          Fingerprint: this.selectedFingerprint,
          Smile: this.molecularFormula,
          Minscore: this.minscore,
          Maxscore: this.maxscore,
        };
        this.showPopper = true;
      }
    },
    SubmitGeneration() {
      console.log(this.catagory[this.selectedClassfication]);
      similarityApi
        .getSimilarity({
          category: this.catagory[this.selectedClassfication],
          smile: this.molecularFormula,
          fingerprint: this.fingerPrint[this.selectedFingerprint],
          min_score: this.minscore,
          max_score: this.maxscore,
          email: this.email,
          title: this.taskName,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$store.commit("alertMsg", {
              msg: `Submit sucessfully. The result will sent to ${this.email} when finished.`,
              type: "ok",
            });
          } else {
            this.$store.commit("alertMsg", {
              msg: res.msg,
              type: "error",
            });
          }
        });
    },
    getFromIframe(value) {
      console.log(value);
      this.molecularFormula = value;
    },
    onSelectModel(catagory, name) {
      this.selectedTarget = name;
    },
  },

  components: {
    "similarity-popper": popper,
  },
};
</script>

<style scoped>
.draw-molecular {
  margin-top: 10px;
}
.title {
  font-size: 30pt;
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
}
.Input {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  width: 80%;
}

.classfication-input {
  margin-top: 10px;
  width: 300px;
}
.small-title {
  font-size: 20pt;
}
.fingerprint-input {
  margin-top: 10px;
  width: 300px;
  /* font-size: 20px; */
}
.smiles-input {
  margin-top: 10px;
  width: 300px;
}
.Input section {
  width: 35%;
}
.fingerprint {
  margin-top: 50px;
}
.range {
  margin-top: 50px;
}
.range div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14pt;
  margin-top: 10px;
}
.range div .el-input {
  width: 75px;
  margin-left: 10px;
  margin-right: 10px;
}
.title-font {
  font-size: 28pt;
  font-weight: 700;
}
.text-font {
  font-size: 14pt;
  font-weight: 500;
}
.MolecularGeneration {
  display: flex;
  flex-direction: column;
  /* background: var(--color1-light); */
  min-height: 80vh;
  padding-top: 60px;
  color: white;
  align-items: center;
}
.column-flex {
  display: flex;
  flex-direction: column;
}
.row-flex {
  display: flex;
  flex-direction: row;
}
.introduction {
  width: 50%;
}

.submit-button:hover {
  background: linear-gradient(to left, #8bbbed, #e8f4ff);
}

.submit-button {
  margin-top: 22px;
  width: 327.5px;
  height: 47px;
  color: black;
  font-weight: bold;
  font-size: 20pt;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(to right, #8bbbed, #e8f4ff);
}
.introduction p {
  font-size: 14pt;
  word-break: break-all;
}
.img {
  width: 70%;
  margin-top: 38px;
}
.example {
  width: 100%;
  height: 500px;
  display: flex;
  background: rgb(151, 248, 255, 0.89);
  flex-direction: column;
}
.submit {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.option-list {
  border-bottom: 1px solid #000;
}
.option-list .el-row {
  height: 30px;
  line-height: 30px;
}
.option-list .el-row .el-col {
  font-size: 14pt;
}

.mode {
  width: fit-content;
  /* background-color: var(--color2-dark);  */
  box-sizing: border-box;
  padding: 0 10px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.mode-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* background: red; */
}

.mode-name {
  font-size: 16pt;
  font-weight: bolder;
  margin: 5px 0;
}

.show-all-btn {
  width: fit-content;
  align-self: end;
  padding: 0 10px;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  align-self: flex-end;
}

.show-all-btn:hover {
  color: var(--color2-dark);
}

.model-select {
  margin: 0 20px;
}

.models {
  width: fit-content;
  height: 40px;
  display: grid;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 10px;
  grid-template-columns: repeat(4, 150px);
  gap: 10px;
}

.models.show-all {
  /* overflow-y: scroll; */
  height: fit-content;
}

.model-item {
  box-sizing: border-box;
  height: 30px;
  background-color: var(--color2-dark);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px;
  text-align: center;
  color: var(--color1-dark);
  display: flex;
}
.model-icon {
  /* width: 10px; */
  padding: 0 2px;
  height: 100%;
}

.model-item a {
  flex: 1;
  display: block;
  text-overflow: ellipsis;
}
.model-item:hover {
  color: white;
  /* white-space: nowrap;
  min-width: fit-content;
  overflow: visible;*/
}
</style>
